<template>
  <div class="approval">
    <v-spacer></v-spacer>
    <ErrorAlert :message="errorMessage" @clear-error="clearMessage" />
    <SuccessAlert :message="successMessage" @clear-error="clearMessage" />
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="d-flex align-center pe-2">
            <div>&nbsp; Tabelul Aprobărilor</div>
            <v-spacer></v-spacer>
            <SearchBar @search="handleSearch" />
            <v-spacer></v-spacer>
            <ColumnSelect
              :headers="headers"
              :updateVisibleColumns="updateVisibleColumns"
            />
          </v-card-title>

          <v-divider></v-divider>
          <v-data-table
            :mobile-breakpoint="0"
            :headers="visibleHeaders"
            :items="filteredData"
            :items-per-page="10"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :loading="isLoading"
            loading-text="Incărcare... Aștepta-ți vă rog"
            class="elevation-1"
          >
            <template v-slot:item="{ item, index }">
              <tr :class="{ 'light-green-row': index % 2 !== 0 }">
                <td
                  v-for="(header, hIndex) in visibleHeaders"
                  :key="hIndex"
                  :style="header.style ? header.style : {}"
                >
                  <template v-if="header.value === 'comment'">
                    <div v-if="item.comment" class="comment-cell">
                      <div class="d-flex align-center">
                        <strong>{{ item.user }}</strong>
                        <span class="ml-2 grey--text text--darken-1">({{ formatDate(item.date) }})</span>
                        <v-chip x-small class="ml-2" :color="getStatusColor(item.status)">
                          {{ item.status }}
                        </v-chip>
                      </div>
                      <div class="comment-text ml-3">{{ item.comment }}</div>
                    </div>
                    <div v-else class="comment-cell text-subtitle-1">-</div>
                  </template>
                  <template v-else-if="header.value === 'comments'">
                    <div v-if="item.requestId" class="comments-wrapper">
                      <v-timeline dense class="compact-timeline">
                        <v-timeline-item
                          v-for="(comment, index) in item.commentsHistory"
                          :key="index"
                          small
                          :color="getStatusColor(comment.status)"
                          class="compact-item"
                        >
                          <div class="comment-header">
                            <strong class="user-name">{{ comment.user }}</strong>
                            <v-chip x-small :color="getStatusColor(comment.status)" class="ml-1">
                              {{ comment.status }}
                            </v-chip>
                          </div>
                          <div class="comment-body">{{ comment.comment }}</div>
                          <div class="comment-date">{{ formatDate(comment.date) }}</div>
                        </v-timeline-item>
                      </v-timeline>
                    </div>
                    <div v-else class="no-comments">-</div>
                  </template>
                  <template v-else>
                    {{ getTableCellValue(item, header, index) }}
                  </template>
                  <div v-if="header.value === 'actions'">
                    <v-btn
                      color="success"
                      outlined
                      small
                      shaped
                      @click="onViewClick(item)"
                      >Aprob</v-btn
                    >
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <ApprovalDetails
      ref="approvalDetails"
      :request="selectedRequest"
      :visible="showApprovalPopup"
      @updateRequestData="fetchApprovalData"
    />
  </div>
</template>

<script>
import activityLogsData from "./activityLogs.json";
import TitleComponent from "@/components/elements/TitleComponent.vue";
import ColumnSelect from "@/components/elements/ColumnSelect.vue";
import ApprovalDetails from "./ApprovalDetails.vue";
import ErrorAlert from "@/components/alerts/ErrorAlert.vue";
import SuccessAlert from "@/components/alerts/SuccessAlert.vue";
import SearchBar from "@/components/elements/SearchBar.vue";
import * as api from "./api";
import { mapGetters } from "vuex";
import * as helper from "@/helper/helper.js";

export default {
  name: "RequestApproval",
  components: {
    TitleComponent,
    ApprovalDetails,
    ErrorAlert,
    ColumnSelect,
    SearchBar,
    SuccessAlert,
  },
  data() {
    return {
      headers: [],
      approvalData: [],
      visibleHeaders: [],
      selectedRequest: null,
      showApprovalPopup: false,
      activityLog: activityLogsData,
      search: "",
      sortBy: "id",
      errorMessage: "",
      successMessage: "",
      sortDesc: true,
      isLoading: true,
      showCommentsDialog: false,
      selectedComments: [],
    };
  },
  methods: {
    async fetchApprovalData() {
      const ApprovalType = {
        USER_CREATED: 1, // acestea sunt id la tipurile de raspunsuri din tabela response_types
        SUPERVISOR_APPROVED: 2,
        RECTOR_APPROVED: 3,
        DTI_DISCRETION: 5,
        DTI_SUPERVISOR_APPROVED: 6,
        CONTABILITY_APPROVED: 11,
      };
      try {
      if (this.isAdmin) {
        this.approvalData = await api.getAllApprovalData();
      } else if (this.isUserSef) {
        this.approvalData = await api.getApprovalDataByTypeAndDepartment(
          ApprovalType.USER_CREATED,
          this.$store.getters.getUser.department
        );
      } else if (this.isRector) {
        this.approvalData = await api.getApprovalDataByType(
          ApprovalType.CONTABILITY_APPROVED
        );
      } else if (this.isContability) { // Add this step for contability approval after rector
        this.approvalData = await api.getApprovalDataByType(
          ApprovalType.SUPERVISOR_APPROVED
        );
      } else if (this.isEngineer) {
        const rectorApprovals = await api.getApprovalDataByType(ApprovalType.RECTOR_APPROVED);
        const dtiApprovals = await api.getApprovalDataByType(ApprovalType.DTI_SUPERVISOR_APPROVED);
        this.approvalData = rectorApprovals.concat(dtiApprovals);
      } else if (this.isDtiSef) {
        const departmentApprovals =
          await api.getApprovalDataByTypeAndDepartment(
            ApprovalType.USER_CREATED,
            this.$store.getters.getUser.department
          );

        const dtiDescretion = await api.getApprovalDataByType(
          ApprovalType.DTI_DISCRETION
        );

        this.approvalData = departmentApprovals.concat(dtiDescretion);
      } else {
        this.approvalData = [];
      }

      this.approvalData = await Promise.all(
        this.approvalData.map(async (item) => {
          if (item.requestId) {
            const comments = await api.getRequestCommentsHistory(item.requestId);
            return { ...item, commentsHistory: comments };
          }
          return item;
        })
      );
      this.isLoading = false;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    },
    onViewClick(item) {
      this.selectedRequest = { ...item };
      this.$refs.approvalDetails.show();
    },
    formatCreatedAt(dateString) {
      const result = helper.formatCreatedAt(dateString);
      return result;
    },
    getTableCellValue(item, header, index) {
      if (header.value === "index") return index + 1;
      if (header.value === "user") return item.user;
      if (header.value === "status") return item.status;
      if (header.value === "equipment") return item.equipment;
      if (header.value === "cause") return item.cause;
      if (header.value === "department") return item.department;
      if (header.value === "date") return this.formatCreatedAt(item.date);
      return "";
    },
    formatDate(dateString) {
      if (!dateString) return '';
      return new Date(dateString).toLocaleString('ro-RO', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      });
    },
    getStatusColor(status) {
      const statusColors = {
        'Respins': 'error',
        'Îndeplinit': 'success',
        'În lucru': 'warning',
        'Imposibil': 'grey',
        'Emis de utilizator': 'primary',
        'Aprobat de Sef al Departamentului': 'info',
        'Aprobat de Rector': 'purple',
        'Aprobat de Contabilitate': 'deep-purple'
      };
      return statusColors[status] || 'grey';
    },
    sortTable(header) {
      const result = helper.sortTable(header);
      return result;
    },
    updateVisibleColumns() {
      this.visibleHeaders = this.headers.filter((header) => header.visible);
    },
    handleSearch(searchTerm) {
      this.search = searchTerm;
    },
    handleError(message) {
      this.errorMessage = message;
    },
    handleSuccess(message) {
      this.successMessage = message;
    },
    clearMessage() {
      this.errorMessage = "";
      this.successMessage = "";
    },
    async showComments(item) {
      if (!item.requestId) return;
      try {
        this.selectedComments = await api.getRequestCommentsHistory(item.requestId);
        this.showCommentsDialog = true;
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    },
  },
  computed: {
    ...mapGetters(["getUser"]),
    isAdmin() {
      return this.$store.getters.getUser.role === "admin";
    },
    isEngineer() {
      return this.$store.getters.getUser.role === "inginer";
    },
    isDtiSef() {
      return this.$store.getters.getUser.role === "dtisef";
    },
    isUserSef() {
      return this.$store.getters.getUser.role === "usersef";
    },
    isUser() {
      return this.$store.getters.getUser.role === "usersef";
    },
    isRector() {
      return this.$store.getters.getUser.role === "rector";
    },
    isContability() {
      return this.$store.getters.getUser.role === "contability";
    },
    filteredData() {
      const result = helper.filteredData(this.approvalData, this.search);
      return result;
    },
  },
  mounted() {
    this.headers = require("./approvalData.json");
    this.visibleHeaders = this.headers.filter(
      (header) => header.defaultVisible
    );
    this.headers.forEach((header) => {
      header.visible = header.defaultVisible;
    });
    this.fetchApprovalData();
    this.$refs.approvalDetails.$on("error", this.handleError);
    this.$refs.approvalDetails.$on("success", this.handleSuccess);
  },
};
</script>

<style scoped>
.approval .v-data-table::v-deep tbody td {
  font-size: 18px;
}

.v-data-table::v-deep th {
  font-size: 20px !important;
  background-color: #2d8659 !important;
  color: white !important;
  white-space: nowrap;
}
.light-green-row {
  background-color: #ecf9f2;
}
@media (max-width: 600px) {
  .hide-on-mobile {
    display: none;
  }
}
.comment-cell {
  max-width: 300px;
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: 4px;
  margin: 4px 0;
}
.comment-text {
  color: #666;
  font-style: italic;
  margin-top: 2px;
  white-space: normal;
  word-wrap: break-word;
}
.comments-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.comments-list {
  max-height: 400px;
  overflow-y: auto;
}

.comment-text {
  white-space: normal;
  word-break: break-word;
}

.comments-wrapper {
  max-height: 150px;
  overflow-y: auto;
  padding: 4px;
}

.compact-timeline {
  padding-top: 0;
}

.compact-item {
  padding-bottom: 8px !important;
}

.comment-header {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
}

.user-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.comment-body {
  font-size: 0.85rem;
  color: #666;
  margin: 2px 0;
  white-space: normal;
  word-break: break-word;
}

.comment-date {
  font-size: 0.75rem;
  color: #999;
}

.no-comments {
  text-align: center;
  color: #999;
}
</style>
